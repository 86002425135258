import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { defineStore } from 'pinia';
import { MODULE_NAMES } from '@/store';
import { PortfolioFarm } from '@/sdk/entities/portfolioFarm';
import { Farm } from '@/sdk/entities/farm';
import { FARMING_ACTION_TYPES } from '../farming/farming.module';
import { FarmingToken } from './models/farming-token';
import { PORTFOLIO_ACTION_TYPES } from '../portfolios/portfolios.module';
import { loadTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { UPDATE_INTERVAL } from '@/helpers/constants';

export const useFarmingTokens = defineStore('farmingTokens', () => {
  const { state, dispatch } = useStore();
  const { updateTokenBalances } = useBalances();

  const intervalId = ref<NodeJS.Timer>();

  const isStoreReady = ref<boolean>(false);
  const tokens = ref<FarmingToken[]>([]);

  const isDataForFarmingReady = computed(() => {
    return state.portfolios.isStoreReady;
  });

  const isFarmingReady = computed(() => {
    const isReady = isDataForFarmingReady.value && state.farming.isStoreReady;
    return isReady;
  });

  const portfolioFarms = computed<PortfolioFarm[]>(() => {
    if (!isFarmingReady.value) return [];

    const farms = Object.values<Farm | PortfolioFarm>(state.farming.lp);
    return farms.filter<PortfolioFarm>(
      (farm): farm is PortfolioFarm => farm instanceof PortfolioFarm,
    );
  });

  const loadFarms = () => {
    dispatch(`${MODULE_NAMES.FARMING}/${FARMING_ACTION_TYPES.FARMING_INIT}`);
  };

  const generateFarmingTokens = () => {
    const singleSideTokens: FarmingToken[] = [];
    portfolioFarms.value.forEach(farm => {
      // NOTE: Exclude cross chain portfolios.
      if (farm.portfolio.type === PortfolioSource.PORTFOLIO_CROSSCHAIN) {
        return;
      }
      farm.portfolio.tokens.forEach(tokenInfo => {
        singleSideTokens.push({
          tokenInfo,
          farm,
        });
      });
    });

    console.log('[SINGLE SIDE] TOKENS : ', singleSideTokens);
    tokens.value = singleSideTokens;
    isStoreReady.value = true;
  };

  const callListOfDepsWhichNeedUpdateForFarmingToken = async () => {
    await loadTokens();
    await updateTokenBalances();
    await dispatch(MODULE_NAMES.PORTFOLIOS + '/' + PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS);
  };

  const updateFarmingToken = async () => {
    isStoreReady.value = false;
    await callListOfDepsWhichNeedUpdateForFarmingToken();
  };

  const cleanIntervalUpdateFarmingToken = () => {
    intervalId.value ? clearInterval(intervalId.value) : undefined;
    intervalId.value = undefined;
  };

  const startIntervalUpdateFarmingToken = () => {
    cleanIntervalUpdateFarmingToken();
    intervalId.value = setInterval(callListOfDepsWhichNeedUpdateForFarmingToken, UPDATE_INTERVAL);
  };

  watch(
    isDataForFarmingReady,
    isReady => {
      if (isReady) {
        console.log('LOADING FARMS');
        loadFarms();
      }
    },
    { immediate: true },
  );

  watch(isFarmingReady, isReady => {
    if (isReady) generateFarmingTokens();
  });

  return {
    isStoreReady,
    tokens,
    updateFarmingToken,
    startIntervalUpdateFarmingToken,
    cleanIntervalUpdateFarmingToken,
  };
});
