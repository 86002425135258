import _ from 'lodash';
import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { IStakingCompoundForm } from '@/store/modules/staking/models/staking-compound-form.interface';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { BridgeToken } from '@/composables/milkomeda-wrapped-smartcontract/models/bridge-token';
import { StakingManualPoolTitle } from '@/store/modules/staking/models/staking-pool';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useStakingMilkomedaWSCBridge } from '@/store/modules/staking/useStakingMilkomedaWSCBridge';
import { useManualStakingTransactions } from '@/composables/staking/manual/useManualStakingTransactions';

export const useStakingCompound = defineStore('stakingCompound', () => {
  const { t } = useI18n();
  const { getTokenBySymbolAndChainId } = useTokens();
  const {
    setBridgeTokensFromCardano,
    setIsEVMFromCardano,
    setHasBridgeFromMilkomeda,
    $reset: resetMilkomedaWSCBridge,
  } = useStakingMilkomedaWSCBridge();
  const { compound } = useManualStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingCompoundForm = reactive<IStakingCompoundForm>({
    pool: null,
    token: null,
  });

  const cardanoADATokenForBridge = computed<Token>(() => {
    return getTokenBySymbolAndChainId('ADA', +DEFAULT_CARDANO_CHAIN_ID);
  });

  function $reset(): void {
    stakingCompoundForm.pool = null;
  }

  function checkCompoundForBridgeFromCardano() {
    if (!ENABLE_FAKE_CARDANO_NETWORK) return;
    const token = cardanoADATokenForBridge.value!;

    resetMilkomedaWSCBridge();
    setIsEVMFromCardano(false);
    setHasBridgeFromMilkomeda(false);
    const bridgeTokens: BridgeToken[] = [
      {
        amount: '0',
        token,
      },
    ];

    setBridgeTokensFromCardano(bridgeTokens);
  }

  function setCompoundFromPool(pool: Staking, token: Token): void {
    stakingCompoundForm.pool = pool;
    stakingCompoundForm.token = token;
  }

  async function doCompound() {
    console.groupCollapsed('[POOL:COMPOUND] doCompound');
    const cStakingCompoundForm: IStakingCompoundForm = _.cloneDeep(stakingCompoundForm);

    if (!cStakingCompoundForm.pool) return;
    if (!cStakingCompoundForm.token) return;

    const token = cStakingCompoundForm.token;
    const poolTitle = cStakingCompoundForm.pool.isSyrupPool ? 'syrup' : 'blues';
    const notificationId = `staking_compound_${poolTitle}_${token.address}`;

    try {
      addNotification(
        getCompoundNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      await compound(cStakingCompoundForm.pool);

      addNotification(
        getCompoundNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'success',
        }),
      );
    } catch (error) {
      console.error(`[POOL:COMPOUND] Happen error during compound operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getCompoundNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw Error(error);
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getCompoundNotificationOptions(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    options: {
      id: string;
      status: NotificationStatus;
    },
  ): INotification {
    return {
      ...options,
      content: buildCompoundNotificationContent(token, poolTitle, options.status),
    };
  }

  function buildCompoundNotificationContent(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    status: NotificationStatus,
  ): string {
    const poolTitleCaption = t(`yieldPool.manual.poolTitle.${poolTitle}.caption`, {
      name: token.name,
    });
    const compoundingFromPool = `${t('compoundingEarnings')} ${t('from')} ${poolTitleCaption}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: compoundingFromPool,
      success: `${compoundingFromPool} ${t('succeeded')}`,
      error: `${compoundingFromPool} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    stakingCompoundForm,
    checkCompoundForBridgeFromCardano,
    setCompoundFromPool,
    doCompound,
    $reset,
  };
});
