import { useLocalStorage } from '@/composables/useLocalStorage';
import { isCardanoNativeToken } from '@/store/modules/tokens/utils';
import { CardanoWalletState } from '@/store/modules/wallet/models/cardano-wallet.state';
import { toCapitalize } from '@/utils/string';
import { reloadPage } from '@/utils/page';
import BigNumber from 'bignumber.js';
import { defineStore } from 'pinia';
import { Loader } from 'crypto-sdk';
import { Blockfrost } from 'crypto-sdk';
import { CardanoWallet } from 'crypto-sdk';
import { MilkomedaBridgeProvider } from 'crypto-sdk';
import { IWalletsList } from './models/wallets-list.interface';
import { BLOCKFROST_API_KEYS } from '@/constants/BLOCKFROST_API_KEYS';

const localStorageWalletKey = 'cardano-wallet';

export const useCardanoWallet = defineStore('cardanoWallet', {
  state: (): CardanoWalletState => ({
    installedWallets: [],
    supportedWallets: [],
    networkId: null,
    isNetworkSupported: null,
    address: '',
    blockfrost: null,
    wallet: null,
    bridgeProvider: null,
  }),
  actions: {
    async init() {
      await Loader.load();
      this.blockfrost = new Blockfrost(BLOCKFROST_API_KEYS);

      initWallet(this);
      setPossibleWallets(this);

      const previouslyConnectedWallet = useLocalStorage().getItem<string>(localStorageWalletKey);
      if (previouslyConnectedWallet) {
        await this.login(previouslyConnectedWallet);
      }
    },
    async login(walletName: string): Promise<void> {
      if (!this.wallet) {
        throw new Error('Cardano wallet has not been initialized.');
      }

      try {
        await this.wallet.login(walletName.toLowerCase());
        this.networkId = await this.wallet.getNetworkId();

        const defaultCardanoId = process.env.VUE_APP_DEFAULT_CARDANO_ID ?? 1;
        this.isNetworkSupported = this.networkId == +defaultCardanoId;

        this.address = await this.wallet.getAddress()!;

        useLocalStorage().setItem(localStorageWalletKey, walletName);

        // NOTE:
        // It is not critical that some Cardano wallets do not support `on` method.
        try {
          await this.wallet.on?.('accountChange', reloadPage);
          await this.wallet.on?.('networkChange', reloadPage);
        } catch (error) {
          console.warn('[CARDANO:WALLET] ', error);
        }
      } catch (error) {
        console.error('[CARDANO:WALLET] Happen error while Cardano wallet login. Error: ', error);

        throw error;
      }
    },
    async logout(): Promise<void> {
      // NOTE:
      // It is not critical that some Cardano wallets do not support `off` method.
      try {
        await this.wallet?.off('accountChange', reloadPage);
        await this.wallet?.off('networkChange', reloadPage);
      } catch (error) {
        console.warn('[CARDANO:WALLET] ', error);
      }

      this.$reset();

      useLocalStorage().removeItem(localStorageWalletKey);

      return this.init();
    },
    async getBalance(address: string): Promise<BigNumber> {
      if (!this.wallet || !this.isInjected) {
        throw new Error(`Wallet must be connected before balances fetch.`);
      }

      if (isCardanoNativeToken(address)) {
        const balanceResponse: { quantity: string }[] = await this.wallet.getBalance();

        return new BigNumber(balanceResponse[0].quantity);
      }

      const tokenBalance: string = await this.wallet.getBalanceToken(address);

      return new BigNumber(tokenBalance);
    },
    async awaitForTransaction(transactionHash: string): Promise<string> {
      return await this.blockfrost!.getTxBlockHash(transactionHash, this.networkId!);
    },
  },
  getters: {
    getAvailableConnectorsList(): IWalletsList[] {
      return this.supportedWallets.map((value, index) => {
        return <IWalletsList>{
          id: index.toString(),
          name: toCapitalize(value),
          icon: `/images/connectors/${value}.svg`,
          isInstalled: this.installedWallets.includes(value),
        };
      });
    },
    isInjected(): boolean {
      return !!this.address;
    },
  },
});

function initWallet(store: ReturnType<typeof useCardanoWallet>): void {
  if (!store.blockfrost) {
    throw new Error('Blockfrost must be initialized before wallet initialization.');
  }

  store.wallet = new CardanoWallet();
  store.wallet.setBlockchainProvider(store.blockfrost);
  const bridgeProvider = new MilkomedaBridgeProvider();
  store.bridgeProvider = bridgeProvider;
  store.wallet.setBridgeProvider(bridgeProvider);
}

function setPossibleWallets(store: ReturnType<typeof useCardanoWallet>): void {
  if (!store.wallet) {
    throw new Error('Cardano wallet has not been initialized.');
  }

  store.supportedWallets = [
    ...CardanoWallet.supportedWallets,
    ...CardanoWallet.experimentalWallets,
  ];
  store.installedWallets = store.wallet.getAvailableNames();
}
