<template>
  <div>
    <div class="caption">{{ $t('portfolio.addLiquidity.confirmDialog.totalInvestment') }}</div>
    <div class="dialog-detail mt-s2">
      <div
        v-for="(tokenAndAmount, index) in getPortfolioTokensAndAmounts()"
        :key="tokenAndAmount.token.symbol"
        class="flex justify-content-between"
        :class="{ 'mt-s2': index !== 0 }"
      >
        <TokenIcon
          :icon-src="tokenAndAmount.token.tokenIconUrl"
          :symbol="tokenAndAmount.token.unwrapWETH().symbol"
          size="20"
          full
        />
        <div>{{ format(tokenAndAmount.amount) }}</div>
      </div>
    </div>
    <div class="dialog-data mt-s4" v-if="!lpAmountLoadingError">
      <div class="flex justify-content-between">
        <div class="caption">{{ $t('in') }}&nbsp;{{ $t('lpTokens') }}</div>
        <Skeleton v-if="isLpAmountOutLoading" class="w-5"></Skeleton>
        <LpAmountFormat :lp-amount="lpAmountOutFull" is-short v-else />
      </div>
      <div class="flex justify-content-between mt-s2">
        <div class="caption">{{ $t('in') }}&nbsp;{{ $t('baseCurrency').toLowerCase() }}</div>
        <Skeleton v-if="isLpAmountOutLoading" class="w-5"></Skeleton>
        <div v-else>
          {{ format(totalReceiveValueInBase) }}&nbsp;
          {{ portfolio.baseToken.unwrapWETH().symbol }}
        </div>
      </div>
      <div class="flex justify-content-between mt-s2">
        <div class="caption">{{ $t('in') }}&nbsp;USD</div>
        <Skeleton v-if="isLpAmountOutLoading" class="w-5"></Skeleton>
        <div v-else>${{ format(totalReceiveValueInUSD) }}</div>
      </div>
    </div>

    <div class="caption mt-s3">
      {{ $t('portfolio.loss.caption') }}
    </div>

    <Callout
      v-if="impactInfoType !== 'none' && !lpAmountLoadingError"
      :type="impactInfoType"
      class="mt-s2"
    >
      <template v-slot:content>Fee and Price Impact </template>
      <template v-slot:aside>
        <span :class="'text-' + impactInfoType">{{ usdSymbolAndAmount || '$0' }}</span>
        <span>({{ impactPercentFormatted || '0' }}%)</span>
      </template>
    </Callout>
    <CalloutWithTooltip
      v-if="lpAmountLoadingError"
      type="danger"
      class="mt-s4"
      :tooltip="$t('portfolio.toDecreasePriceImpactDeposit')"
    >
      {{ $t('portfolio.weProtectingYou') }}
    </CalloutWithTooltip>
    <div
      class="flex mt-s4 dialog-detail align-items-center justify-content-between"
      v-if="crossChainFeePercent"
    >
      <Caption
        :caption="$t('portfolio.crosschainFee.caption')"
        :tooltip="$t('portfolio.crosschainFee.tooltip')"
      />
      <div>{{ crossChainFeeForPrint }}</div>
    </div>
    <div class="mt-s6 text-muted">
      {{ $t('portfolio.addLiquidity.confirmDialog.approveDescription') }}
    </div>
    <div class="dialog-footer dialog-footer-two-btn">
      <Button class="button-base" :label="$t('cancel')" @click="cancel()" />
      <Button
        class="button-primary"
        :label="$t('portfolio.addLiquidity.confirmDialog.confirmBtn')"
        @click="onConfirm()"
        v-gtm="GTM_ADD_LIQUIDITY_BUTTON"
        :disabled="transactionInProgress || isLpAmountOutLoading || lpAmountLoadingError"
      >
        <Spinner color="white" size="24" v-if="isLpAmountOutLoading" />
        <template v-else>
          <Spinner color="white" size="24" class="mr-s2" v-if="transactionInProgress" />
          {{ $t('portfolio.addLiquidity.confirmDialog.confirmBtn') }}
        </template>
      </Button>
    </div>
    <Dialog
      v-model:visible="isWarningDialogShown"
      :show-header="false"
      dismissableMask
      :modal="true"
    >
      <PriceImpactWarning
        type="deposit"
        :impact-percent="+impactPercentFormatted"
        :totally-provide-u-s-d="totalEnteredValueInUSD"
        :will-receive-u-s-d="totalReceiveValueInUSD"
        @cancel="cancel()"
        @confirm="onConfirmInWarningModal()"
      />
    </Dialog>
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import { Portfolio } from '@/sdk/entities/portfolio';
import TokenIcon from '@/components/token/TokenIcon.vue';
import roundMixin from '@/mixins/round.mixin';
import Spinner from '@/components/Spinner';
import weiMixin from '@/mixins/wei.mixin';
import Callout from '@/components/Callout';
import { BIG_ZERO } from '@/utils/bigNumber';
import { formatNumbersMixin } from '@/mixins/format-numbers.mixin';
import CalloutWithTooltip from '@/components/CalloutWithTooltip';
import PriceImpactWarning from './PriceImpactWarning';
import { DEPOSIT_PRICE_IMPACT_WARNING_PERCENT_FORM } from '@/helpers/constants';
import { GTM_CLASSES } from '@/constants/gtmClasses';
import { toDecimalPlacesOrLT } from '@/utils/format';
import { useEasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeForm';
import { useEasyModeAddLiquidityCalculation } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/composables/useEasyModeAddLiquidityCalculation';
import Caption from '@/components/Caption.vue';
import LpAmountFormat from '@/components/LpAmountFormat.vue';

export default {
  name: 'AddLiquidityConfirmation',
  components: {
    LpAmountFormat,
    Caption,
    TokenIcon,
    PriceImpactWarning,
    Callout,
    Spinner,
    CalloutWithTooltip,
  },
  mixins: [roundMixin, weiMixin, formatNumbersMixin],
  props: {
    cancel: Function,
    confirm: Function,
    form: Object,
    portfolio: Portfolio,
    transactionInProgress: { type: Boolean, default: false },
    crossChainFeeUSD: BigNumber.Value,
    crossChainFeePercent: BigNumber.Value,
  },
  data() {
    return {
      lpAmountOut: null,
      lpAmountLoadingError: false,
      isWarningDialogShown: false,
      GTM_ADD_LIQUIDITY_BUTTON: GTM_CLASSES.GTM_ADD_LIQUIDITY_BUTTON,
    };
  },
  computed: {
    impactPercent() {
      const { easyModeForm } = useEasyModeForm();
      return easyModeForm.lossPercent ?? BIG_ZERO;
    },
    impactPercentFormatted() {
      return toDecimalPlacesOrLT(this.impactPercent);
    },
    usdSymbolAndAmount() {
      const { lossUSD } = useEasyModeForm();
      return toDecimalPlacesOrLT(lossUSD.value, { addUsdSymbol: true });
    },
    totalEnteredValueInBase() {
      return this.portfolio.getYouWillDeposit(this.form);
    },
    totalEnteredValueInUSD() {
      return this.portfolio.getYouWillDepositUSD(this.totalEnteredValueInBase);
    },
    totalReceiveValueInBase() {
      if (this.isLpAmountOutLoading || !this.lpAmountOut) {
        return null;
      }

      return this.portfolio.getYouWillDepositBase(this.lpAmountOut);
    },
    totalReceiveValueInUSD() {
      if (this.isLpAmountOutLoading) {
        return null;
      }

      const lpRelativePriceInUSD = this.portfolio.getLpTokenPriceUSD();

      return lpRelativePriceInUSD.multipliedBy(this.lpAmountRelative);
    },
    lpAmountOutFormatted() {
      if (this.isLpAmountOutLoading) {
        return '';
      }
      return this.format(this.lpAmountRelative);
    },
    lpAmountOutFull() {
      if (this.isLpAmountOutLoading) {
        return '';
      }
      return this.lpAmountRelative;
    },
    lpAmountRelative() {
      if (this.isLpAmountOutLoading) {
        return null;
      }

      // NOTE: Changed `LP_TOKEN_DECIMALS` -> this.portfolio.lpToken.decimals
      return this.fromWei(this.lpAmountOut, this.portfolio.lpToken.decimals);
    },
    isLpAmountOutLoading() {
      return !this.lpAmountOut;
    },
    impactInfoType() {
      if (this.isLpAmountOutLoading) {
        return 'none';
      }

      const smallFeeLimit = '0.01';
      if (this.impactPercent.isLessThanOrEqualTo(smallFeeLimit)) {
        return 'success';
      }

      return 'warning';
    },
    crossChainFeePercentFormatted() {
      return toDecimalPlacesOrLT(this.crossChainFeePercent);
    },
    crossChainFeeUSDFormatted() {
      return toDecimalPlacesOrLT(this.crossChainFeeUSD, { addUsdSymbol: true });
    },
    crossChainFeeForPrint() {
      return `${this.crossChainFeeUSDFormatted} (${this.crossChainFeePercentFormatted}%)`;
    },
  },
  async created() {
    const { easyModeForm } = useEasyModeForm();
    const { calculateLimits } = useEasyModeAddLiquidityCalculation();
    await calculateLimits();
    this.lpAmountOut = easyModeForm.lpAmountOut;
    this.lpAmountLoadingError = easyModeForm.impactError;
  },
  methods: {
    getPortfolioTokensAndAmounts() {
      const { getPortfolioAvailableTokens } = useEasyModeForm();
      const portfolioTokens = getPortfolioAvailableTokens(this.portfolio);

      const tokensAndAmountsArray = [];
      Object.keys(this.form).forEach(key => {
        const tokenInfo = portfolioTokens.find(elem => elem.tokenAddress === key);
        if (tokenInfo && this.form[key].value !== '') {
          tokensAndAmountsArray.push({
            token: tokenInfo.token,
            amount: this.form[key].value,
          });
        }
      });
      return tokensAndAmountsArray;
    },
    onConfirm() {
      if (this.impactPercent.gt(DEPOSIT_PRICE_IMPACT_WARNING_PERCENT_FORM)) {
        this.isWarningDialogShown = true;
        return;
      }
      const { easyModeForm } = useEasyModeForm();
      easyModeForm.lpAmountOut = this.lpAmountOut;
      this.confirm(this.lpAmountOut);
    },
    onConfirmInWarningModal() {
      this.isWarningDialogShown = false;
      this.confirm(this.lpAmountOut);
    },
  },
};
</script>

<style scoped></style>
