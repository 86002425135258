import { computed } from 'vue';
import { Network } from '@/helpers/apollo/query';
import { SELECTED_NETWORK_NAME } from '@/helpers/networkParams.helper';
import { ENABLE_FAKE_CARDANO_NETWORK } from '@/helpers/fakeCardanoNetwork';

export const isAnalyticNetworkSupported = computed(
  () =>
    !!Object.values(Network).find(
      network => network === SELECTED_NETWORK_NAME.toLocaleUpperCase(),
    ),
);
