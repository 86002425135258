import _ from 'lodash';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import {
  IStakingUnstakeForm,
  UnstakeOptions,
} from '@/store/modules/staking/models/staking-unstake-form.interface';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useAutoStakingTransactions } from '@/composables/staking/auto/useAutoStakingTransactions';

export const useAutoStakingUnstake = defineStore('autoStakingUnstake', () => {
  const { t } = useI18n();
  const { unstake, unstakeAll } = useAutoStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingUnstakeForm = reactive<IStakingUnstakeForm>({
    pool: null,
    input: {
      amountIn: null, // In WEI
      token: null,
    },
    mode: null,
  });

  function $reset(): void {
    stakingUnstakeForm.pool = null;
    stakingUnstakeForm.input = {
      amountIn: null,
      token: null,
    };
    stakingUnstakeForm.mode = null;
  }

  function setUnstakeFromFarm(
    pool: Staking,
    token: Token,
    amountInWei: string,
    options: UnstakeOptions,
  ): void {
    stakingUnstakeForm.pool = pool;
    stakingUnstakeForm.input = {
      amountIn: amountInWei,
      token,
    };
    stakingUnstakeForm.mode = options.mode;
  }

  async function doUnstake() {
    console.groupCollapsed('[AUTO:POOL:UNSTAKE] doUnstake');
    const cStakingUnstakeForm: IStakingUnstakeForm = _.cloneDeep(stakingUnstakeForm);

    if (!cStakingUnstakeForm.input.token) return;

    const token = cStakingUnstakeForm.input.token;
    const notificationId = 'auto_pool_unstaking';

    try {
      addNotification(
        getAutoUnstakeNotificationOptions(token, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      if (cStakingUnstakeForm.mode === 'ALL') {
        await unstakeAll();
      } else {
        await unstake(cStakingUnstakeForm.input.amountIn ?? '0');
      }

      addNotification(
        getAutoUnstakeNotificationOptions(token, {
          id: notificationId,
          status: 'success',
        }),
      );
    } catch (error) {
      console.error(`[AUTO:POOL:UNSTAKE] Happen error during unstake operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }
      addNotification(
        getAutoUnstakeNotificationOptions(token, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw Error(error);
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getAutoUnstakeNotificationOptions(
    token: Token,
    options: {
      id: string;
      status: NotificationStatus;
    },
  ): INotification {
    return {
      ...options,
      content: buildAutoUnstakeNotificationContent(token, options.status),
    };
  }

  function buildAutoUnstakeNotificationContent(token: Token, status: NotificationStatus): string {
    const pooTitle = t(`yieldPool.auto.poolTitle.caption`);
    const unstakingTokenFromPool = `${t('unstaking')} ${token.symbol} ${t('from')} ${pooTitle}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: unstakingTokenFromPool,
      success: `${unstakingTokenFromPool} ${t('succeeded')}`,
      error: `${unstakingTokenFromPool} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    stakingUnstakeForm,
    setUnstakeFromFarm,
    doUnstake,
    $reset,
  };
});
