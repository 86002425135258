import _ from 'lodash';
import { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import { Staking } from '@/sdk/entities/staking';
import { Token } from '@/sdk/entities/token';
import { IStakingUnstakeForm } from '@/store/modules/staking/models/staking-unstake-form.interface';
import {
  INotification,
  NotificationStatus,
} from '@/store/modules/notifications/models/notification.interface';
import { StakingManualPoolTitle } from '@/store/modules/staking/models/staking-pool';
import { useNotifications } from '@/store/modules/notifications/useNotifications';
import { useManualStakingTransactions } from '@/composables/staking/manual/useManualStakingTransactions';

export const useManualStakingUnstake = defineStore('manualStakingUnstake', () => {
  const { t } = useI18n();
  const { unstake } = useManualStakingTransactions();
  const { addNotification } = useNotifications();

  const stakingUnstakeForm = reactive<IStakingUnstakeForm>({
    pool: null,
    input: {
      amountIn: null, // In WEI
      token: null,
    },
    mode: null,
  });

  function $reset(): void {
    stakingUnstakeForm.pool = null;
    stakingUnstakeForm.input = {
      amountIn: null,
      token: null,
    };
    stakingUnstakeForm.mode = null;
  }

  function setUnstakeFromPool(pool: Staking, token: Token, amountInWei: string): void {
    stakingUnstakeForm.pool = pool;
    stakingUnstakeForm.input = {
      amountIn: amountInWei,
      token,
    };
  }

  async function doUnstake() {
    console.groupCollapsed('[MANUAL:POOL:UNSTAKE] doUnstake');
    const cStakingUnstakeForm: IStakingUnstakeForm = _.cloneDeep(stakingUnstakeForm);

    if (!cStakingUnstakeForm.pool) return;
    if (!cStakingUnstakeForm.input.token) return;

    const token = cStakingUnstakeForm.input.token;
    const poolTitle = cStakingUnstakeForm.pool.isSyrupPool ? 'syrup' : 'blues';
    const notificationId = `manual_staking_unstake_${poolTitle}_${token.address}`;

    try {
      addNotification(
        getManualUnstakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'inProgress',
        }),
      );

      await unstake(cStakingUnstakeForm.pool, cStakingUnstakeForm.input.amountIn ?? '0');

      addNotification(
        getManualUnstakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'success',
        }),
      );
    } catch (error) {
      console.error(`[MANUAL:POOL:UNSTAKE] Happen error during unstake operation. ERROR : `, error);
      if (error.name === 'ProviderRpcError') {
        console.error(`[ERROR] ProviderRpcError. Error details : `, {
          code: error.code,
          data: error.data,
        });
      }

      addNotification(
        getManualUnstakeNotificationOptions(token, poolTitle, {
          id: notificationId,
          status: 'error',
        }),
      );

      throw Error(error);
    } finally {
      console.groupEnd();
    }
  }

  // NOTIFICATIONS

  function getManualUnstakeNotificationOptions(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    options: {
      id: string;
      status: NotificationStatus;
    },
  ): INotification {
    return {
      ...options,
      content: buildManualUnstakeNotificationContent(token, poolTitle, options.status),
    };
  }

  function buildManualUnstakeNotificationContent(
    token: Token,
    poolTitle: StakingManualPoolTitle,
    status: NotificationStatus,
  ): string {
    const poolTitleCaption = t(`yieldPool.manual.poolTitle.${poolTitle}.caption`, {
      name: token.name,
    });
    const unstakingTokenFromPool =
      `${t('unstaking')} ${token.symbol} ${t('from')}` + ' ' + `${poolTitleCaption}`;

    const notifications: Record<NotificationStatus, string> = {
      inProgress: unstakingTokenFromPool,
      success: `${unstakingTokenFromPool} ${t('succeeded')}`,
      error: `${unstakingTokenFromPool} ${t('failed')}`,
    };

    return notifications[status];
  }
  // ====

  return {
    stakingUnstakeForm,
    setUnstakeFromPool,
    doUnstake,
    $reset,
  };
});
