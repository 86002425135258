import { computed, onBeforeUnmount, ref } from 'vue';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { asyncFnCancelable } from '@/utils/promise';
import { usePortfolios } from '@/composables/portfolios/usePortfolios';
import { usePortfoliosActions } from '@/composables/portfolios/usePortfoliosActions';

export function usePortfoliosUpdater() {
  const { isPortfoliosUpdated } = usePortfolios();
  const { updatePortfoliosInfo } = usePortfoliosActions();

  const updateController = ref<AbortController>(new AbortController());
  const updateInterval = ref<NodeJS.Timer>();

  const isEnabledPortfoliosUpdater = computed(() => !!updateInterval.value);

  async function enablePortfoliosUpdater() {
    if (isEnabledPortfoliosUpdater.value) return;

    try {
      // NOTE: while portfolios are updating, need wait when previous update will finish.
      if (isPortfoliosUpdated.value) {
        await asyncFnCancelable(updatePortfoliosInfo, updateController.value.signal);
      }
    } catch (error) {
      if (error.currentTarget instanceof AbortSignal) {
        return;
      }

      // NOTE:
      // When user open this page and UI try to update portfolios, error can happen.
      // When error then we will setInterval and show available portfolios.
      console.warn('[PORTFOLIOS:UPDATE] Error when portfolios updated: ', error);
    }

    updateInterval.value = setInterval(async () => {
      // NOTE: while portfolios are updating, need wait when previous update will finish.
      if (!isPortfoliosUpdated.value) return;

      await updatePortfoliosInfo();
    }, UPDATE_INTERVAL);
    console.info('[PORTFOLIOS:UPDATE] Portfolios update enabled.');
  }

  onBeforeUnmount(() => {
    updateController.value.abort();

    clearInterval(updateInterval.value);
    updateInterval.value = undefined;
    console.info('[PORTFOLIOS:UPDATE] Portfolios update disabled.');
  });

  return {
    isEnabledPortfoliosUpdater,
    enablePortfoliosUpdater,
  };
}
