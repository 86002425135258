<template>
  <div class="dialog-header mb-s4">
    <span class="dialog-title text-warning">
      {{ $t('portfolio.priceImpactWarning', { impactPercent }) }}
    </span>
    <ControlIcon class="dialog-header-close" name="close" size="20" @click="$emit('cancel')" />
  </div>
  <div class="panel-data mb-s4">
    <div class="flex text-sm justify-content-between mb-s2">
      <span class="text-muted">Totally provide equivalent to</span>
      <span>${{ totallyProvideFormatted }}</span>
    </div>
    <div class="flex text-sm justify-content-between mb-s2">
      <span class="text-muted">You will receive equivalent to</span>
      <span>${{ willReceiveFormatted }}</span>
    </div>
    <div class="flex text-sm justify-content-between text-warning">
      <span>Potential loss</span>
      <span>${{ potentialLossFormatted }}</span>
    </div>
  </div>
  <div class="mb-s6">
    <span class="text-muted">{{ $t(tooltipLabelTranslationKey) }}</span>
    <TooltipIcon :tooltip="$t(tooltipTranslationKey)" inline />
  </div>
  <div class="flex">
    <Button class="flex-1 button-primary mr-s2" :label="$t('cancel')" @click="$emit('cancel')" />
    <Button class="flex-1 button-base" :label="$t('agree')" @click="$emit('confirm')">
      {{ $t('confirm') }}
    </Button>
  </div>
</template>

<script>
import ControlIcon from '@/components/icon/ControlIcon';
import TooltipIcon from '@/components/tooltip/TooltipIcon.vue';
import BigNumber from 'bignumber.js';

export default {
  name: 'PriceImpactWarning',
  components: { ControlIcon, TooltipIcon },
  emits: ['cancel', 'confirm'],
  props: {
    type: {
      validator(value) {
        return ['deposit', 'withdraw'].includes(value);
      },
    },
    impactPercent: Number,
    totallyProvideUSD: BigNumber,
    willReceiveUSD: BigNumber,
  },
  computed: {
    tooltipTranslationKey() {
      return this.type === 'deposit'
        ? 'portfolio.priceImpactTooltipDeposit'
        : 'portfolio.priceImpactTooltipWithdraw';
    },
    tooltipLabelTranslationKey() {
      return this.type === 'deposit'
        ? 'portfolio.youCanReduceYourLossDeposit'
        : 'portfolio.youCanReduceYourLossWithdraw';
    },
    totallyProvideFormatted() {
      return this.formatBigNumberAsUSD(this.totallyProvideUSD);
    },
    willReceiveFormatted() {
      return this.formatBigNumberAsUSD(this.willReceiveUSD);
    },
    potentialLossFormatted() {
      return this.formatBigNumberAsUSD(this.totallyProvideUSD.minus(this.willReceiveUSD));
    },
  },
  methods: {
    formatBigNumberAsUSD(value) {
      return value.decimalPlaces(2).toNumber();
    },
  },
};
</script>

<style scoped></style>
